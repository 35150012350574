<template>
  <div class="selectdomain">
    <title-top>选择地区</title-top>
    <div class="column font28 f-1A1A1A bg-white mt16">
      <span class="row align-center ml25 h72" v-for="(item,index) in list" :key="index" @click="onItem(item.id,item.name)">{{item.name}}</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import {districts} from 'network/igou2'
export default {
  name: "SelectDomain",
  data() {
    return {
      list:[],
      id:'',
      district_name:''
    };
  },
  mounted() {
    this._districts();
  },
  methods:{
    _districts(id){
      districts(id).then(res=>{
        console.log(res);
        
        if(res.data.length == 0){
          localStorage.setItem('district',JSON.stringify({district_id:this.id,district_name:this.district_name}));
          this.$router.back();
        }else {
          this.list = res.data;
        }
      })
    },
    onItem(id,district_name){
      this.id = id;
      this.district_name = district_name;
      this._districts(id);
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
